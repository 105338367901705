
  <template>
  <DatePicker class="block h-full"
              :max-date=maxDate 
              v-model="internalValue2" 
              :masks="dateFMT" 
              @popoverDidHide="$emit('close')" @input=selected style='width: 100%;'>
    <template v-slot="{ inputValue, togglePopover }">
      <div class="flex items-center" style='width: 100%;'>
       
        <input v-if='auto' ref='inp'
          @mouseover="$event.target.focus();"
          @focus="$event.target.select();togglePopover()"
          @blur="$emit('blur')"
          @keyup.esc="escapePressed()"
          @keyup.tab="$emit('tab')"
          :value="inputValue"
          :disabled=disabled
          size=15
          :style="getBGStyle3()+'height: 21pt;width: 100%; border: .1px solid #ddd; border-radius: 4px;'"
          readonly
        /> 
        
        <input v-else ref='inp'
          @mouseover="$event.target.focus();"
          @click="$event.target.select();togglePopover()"
          @blur="$emit('blur')"
          @keyup.esc="escapePressed()"
          @keyup.tab="$emit('tab')"
          :value="internalValue"
          :disabled=disabled
          size=15
          :style="getBGStyle3()+'height: 21pt;width: 100%; border: .1px solid #ddd; border-radius: 4px;'"
          readonly
        />
      </div>
    </template>
  </DatePicker>
</template>

<script>
//import Datepicker from 'vuejs-datepicker';
import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { getAppStyle, setDarkMode, initAppMode, getBGStyle3 } from '@/AppStyle.js';
var momentTZ = require('moment');
export default {
  name: "DatePick",
  components : {
    
    DatePicker
  },
   props: {
    auto: { type: Boolean, default: true },
    minDate: Object,
    maxDate: Object,
    value: {
      type: [Date, String]
    },
    disabled: Boolean
  },
  data () {
    return {
    	 dateFMT : {
			  title: 'MMMM YYYY',
			  weekdays: 'W',
			  navMonths: 'MMM',
			  input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
			  dayPopover: 'WWW, MMM D, YYYY',
			  data: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
			  
			},
			getAppStyle,getBGStyle3
       }
  },
  computed: {
    internalValue: {
      get() {

        //console.log( "get()"+this.value);
        
        let d = new Date(momentTZ(this.value).utcOffset(0, true));
        
        return this.customFormatter(d);
      },
      
      set(v) {
        //alert( "v1> " + this.value+ "/"+v?v.toISOString():"");
        //console.log( "set()"+v);
        // console.log( "set()"+v.toISOString().split('T')[0]);
        //alert(v)
        let x = v.toISOString().split('T')[0]
        this.$emit("input", x);
        
       }
     },
     internalValue2: {
      get() {
            //alert( "v2 " + this.value);
			return this.value;
      },
      set(v) {
        //alert( "v2> " + this.value+ "/"+v?v.toISOString():"");
        // console.log( "set()"+v);
        // console.log( "set()"+v.toISOString().split('T')[0]);
        //alert(v+"/"+v.toISOString()+"/"+v.toLocaleDateString())
        this.$emit("input", v.toLocaleDateString('en-CA'));
        
       }
     }
    },
    methods: {
        escapePressed() { this.$emit("escape"); },
        enterPressed() { this.$emit("enter"); },
        open() { this.$refs.inp.focus(); this.$refs.inp.select();},
		customFormatter(date) {
		return momentTZ(date).format(sessionStorage.dateFormat);
		},
		selected(d)
		{
			this.$emit("change", d);
		},

     },
    watch:
    {
        //dateFormat: function()  { this.displayTime() },
        //internalValue: function()  { this.displayTime() }
    } 
 }
</script>
<style scoped lang="scss">
.dateInputWrapper { 
    border: 0.1px solid #ddd;
    border-radius: 6px;
    padding: 2pt;
    width: 100%;
    font-size: 11pt;
	white-space: normal;
	overflow: visible !important;
}
.comp_datepicker { font-size: 11pt; border: none !important; width: 100%}
.comp_small { font-size: 11pt; padding: 1; height: 10px;}
.comp_datepicker:disabled { font-size: 11pt; border: none; width: 100%; color: #888; background-color: transparent;}


</style>